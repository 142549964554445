import { Box } from '@mui/material';
import {
  ColDef,
  IRowNode,
  RowClickedEvent,
  RowSelectedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Medication } from 'features/Medicals/model/types/medicationTypes';
import { FC } from 'react';

interface propsType {
  columnDefs: ColDef[];
  rowData: any;
  height: string;
  handleClicedRow: (event: RowSelectedEvent<any, any>) => void;
  selectedItems?: any[];
}

export const ReuseableAgGridTable: FC<Partial<propsType>> = (props) => {
  const { selectedItems, columnDefs, handleClicedRow, height, rowData } = props;

  return (
    <Box
      className={`${height ? height : 'h-[15vh]'}  w-full `}
      style={{ height: '400px' }}>
      <Box
        style={{ height: '100%', width: '100%' }}
        className="ag-theme-alpine">
        <AgGridReact
          rowSelection="multiple"
          onRowSelected={handleClicedRow}
          // onRowClicked={handleClicedRow}
          suppressRowClickSelection
          columnDefs={columnDefs}
          rowData={rowData}
          enableRangeSelection
          onFirstDataRendered={(params) => {
            const nodesToSelect: IRowNode<Medication>[] = [];

            params.api.forEachNode((node: IRowNode<Medication>) => {
              if (selectedItems?.some((item) => node.data?.id === item.id)) {
                nodesToSelect.push(node);
              }
            });
            params.api.setNodesSelected({
              nodes: nodesToSelect,
              newValue: true,
            });
          }}
          animateRows
        />
      </Box>
    </Box>
  );
};
