import { MedicalServiceAction, MedicalServiceActionItem, MedicalServiceActions } from 'components/AppointmentActionSection/types';
import { useConsultingResearchSelector } from 'features/ConsultingAndResearch/model/selectors/consultingReserchSelector';
import {
  MedServiceCategoryType,
  MedServiceCategoryTypes,
} from 'features/ConsultingAndResearch/model/types';
import { useGetMedServiceGroupQuery } from 'features/ConsultingAndResearch/service/consultingAndReseachService';
import { useReduxSelector } from 'hooks/useReduxHook';
import { useCallback, useState } from 'react';

export const useConsultingHook = () => {
  const { data: researchData } = useGetMedServiceGroupQuery({});

  return { researchData };
};
