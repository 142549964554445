import { Box, Typography } from '@mui/material';
import SectionTitle from 'components/SectionTitle/sectionTitle';
import DefaultButton from 'components/deafultButton/DefaultButton';
import { useProceduresViewHook } from './hook';
import AdvancedModal from 'components/Modals/ReuseableModal/reuseableModal';
import { ProceduresModalView } from '../components/ProceduresModal';
import { ProceduresTable } from './ProceduresTable';

export const ProceduresView = () => {
  const {
    isOpen,
    toggleMedicalsModal,
    handleClickOk,
    handleProceduresSelectItems,
    selectedItems,
  } = useProceduresViewHook();

  return (
    <Box className="border p-2 my-2 ">
      <SectionTitle
        title="Лечебные процедуры"
        className="text-base font-medium"
      />
      <Box className="flex flex-row items-center justify-start gap-3 mb-2">
        <Typography className="text-sm text-black">
          Лечебные процедуры не назначены.
        </Typography>
        <DefaultButton
          classStyle="bg-[#4CAF50] text-[#fff] h-[30px]"
          title="Добавить"
          onClick={toggleMedicalsModal}
        />
        <AdvancedModal
          open={isOpen}
          onCancel={toggleMedicalsModal}
          onClose={handleClickOk}
          size="large"
          children={
            <ProceduresModalView
              handleProceduresSelectItems={handleProceduresSelectItems}
              selectedItems={selectedItems}
            />
          }
          modalTitle={'Выбор назначений'}
        />
      </Box>
      <ProceduresTable />
    </Box>
  );
};
