import { Typography } from '@mui/material';
import { EditIcon } from 'assets/icons/icons';
import { IColumnType } from 'components/CollapseTable/CollapseTable';
import {
  LabResearchesType,
  MedServiceCategoryType,
} from 'features/ConsultingAndResearch/model/types';

export const labResearchesColDef: IColumnType<LabResearchesType>[] = [
  {
    key: '',
    title: '',
    width: 30,
  },
  {
    key: '',
    title: '',
    width: 45,
    render: (column, item) => {
      return <div className=" flex items-center justify-center">status</div>;
    },
  },
  {
    key: 'name',
    title: 'Назначение',
  },
  {
    key: '',
    title: 'К врачу',
    width: 140,
  },
  {
    key: 'cito',
    title: 'Cito?',
    render: (column, item) => {
      return (
        <Typography className="font-roboto text-sm font-medium text-blue-400">
          нет
        </Typography>
      );
    },
  },
  {
    key: 'deadline',
    title: 'Дата',
    width: 100,
  },
  {
    key: '',
    title: 'Способ оплаты',
    width: 160,
  },
  {
    key: 'price',
    title: 'Цена',
    width: 120,
  },
  {
    key: '',
    title: `Комментарий (цель исследования!
        консультации)`,
    width: 300,
  },
];

export const consultingItemsColDef: IColumnType<MedServiceCategoryType>[] = [
  {
    key: '',
    title: '',
    width: 30,
  },
  {
    key: '',
    title: '',
    width: 45,
    render: (column, item) => {
      return <div className=" flex items-center justify-center">status</div>;
    },
  },
  {
    key: 'name',
    title: 'Назначение',
  },
  {
    key: 'doctor',
    title: 'К врачу',
    width: 140,
  },
  {
    key: 'cito',
    title: 'Cito?',
    render: (column, item) => {
      return (
        <Typography className="font-roboto text-sm font-medium text-blue-400">
          нет
        </Typography>
      );
    },
  },
  {
    key: 'created_at',
    title: 'Дата',
    width: 100,
  },
  {
    key: '',
    title: 'Способ оплаты',
    width: 160,
  },
  {
    key: 'cost',
    title: 'Цена',
    width: 120,
  },
  {
    key: '',
    title: `Комментарий (цель исследования!
      консультации)`,
    width: 300,
  },
];
