import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { Category } from 'components/AutocomplateCategoryBox/hook';
import React, { useCallback, useState, useEffect } from 'react';
import { FieldValues, Path, UseFormReturn } from 'react-hook-form';
import { substring } from 'utils/substring';

interface Props<T extends FieldValues> {
  categoryData: Category[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fieldname: Path<T>;
  methods: UseFormReturn<any, any, any>;
}

const SearchSelectField = <T extends FieldValues>({
  open,
  setOpen,
  categoryData,
  fieldname,
  methods,
}: Props<T>) => {
  const [options, setOptions] = useState<readonly Category[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [fieldValue, setFieldValue] = useState<null | Category>(null);

  useEffect(() => {
    if (!fieldValue && methods.watch(fieldname) && categoryData.length > 0) {
      const formFieldValue = methods.watch(fieldname);
      const foundCategory = categoryData?.find(
        (c) => c.name === formFieldValue,
      );
      console.log({ foundCategory });
      if (!!foundCategory) {
        setFieldValue(foundCategory);
      }
    } else if (fieldValue?.name !== methods.watch(fieldname)) {
      setFieldValue(null);
    }
  }, [
    JSON.stringify(categoryData),
    methods.watch(fieldname),
    JSON.stringify(fieldValue?.name),
  ]);

  useEffect(() => {
    let active = true;
    if (open && options.length === 0) {
      setLoading(true);
      (async () => {
        await sleep(1000);
        if (active) {
          setOptions([...categoryData]);
          setLoading(false);
        }
      })();
    }
    return () => {
      active = false;
    };
  }, [open, options.length, categoryData]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleAutocompleteChange = (newValue: Category | null) => {
    const newValueId = newValue ? newValue.name : null;
    setFieldValue(newValue);
    methods.setValue(fieldname, newValueId as never);
  };

  return (
    <Autocomplete
      id="asynchronous-demo"
      sx={{
        width: '100%',
      }}
      open={open}
      value={fieldValue}
      onClose={handleClose}
      onClick={() => setOpen(false)}
      onChange={(_event, newValue) => handleAutocompleteChange(newValue)}
      size="small"
      loading={loading}
      options={options}
      getOptionLabel={(option) =>
        option && option.name ? `${substring(option.name, 30)}` : ''
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          className="bg-white border"
          InputProps={{
            ...params.InputProps,
            endAdornment: loading ? (
              <CircularProgress color="inherit" size={20} />
            ) : null,
          }}
        />
      )}
    />
  );
};

export default SearchSelectField;

function sleep(duration: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}
