import { RowSelectedEvent } from 'ag-grid-community';
import { useActionsDataQuery, useCreatePillsMutation } from 'components/AppointmentActionSection/service';
import { PillActions } from 'components/AppointmentActionSection/types';
import dayjs from 'dayjs';
import { setSelectedMedication } from 'features/Medicals/model/slice/medicationSlice';
import { Medication } from 'features/Medicals/model/types/medicationTypes';
import { useReduxDispatch, useReduxSelector } from 'hooks/useReduxHook';
import { useCallback, useEffect, useState } from 'react';
import { Pill } from 'types/patientTypes';
import { useGetMedicationsQuery } from '../service/medicationsService';
import { useFetchAndTransformData } from 'features/Appointments/slice/useAppoitnmentsSelectors';
import { Key } from '@mui/icons-material';

export const useMedicationViewHook = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMedicalsModal = useCallback(() => {
    setIsOpen((oldState) => !oldState);
  }, []);
  const { selectedMedications } = useReduxSelector(s => s.medication);
  const [medications, setMedications] = useState<Pill[]>(selectedMedications)
  console.log({ medications });

  const medicineToPill = (medicine: Medication) => ({
    comments: '',
    end_date: dayjs(new Date).format('YYYY-MM-DD'),
    frequency: 'до еды',
    instruction: '',
    period_days: 0,
    pills_injections: medicine.in_pack,
    quantity: 0,
    state: 'assigned',
    price: medicine.price,
    id: medicine.id,
    created_by: medicine.created_by,
    modified_by: medicine.modified_by,
  })

  const dispatch = useReduxDispatch();

  const [createPill] = useCreatePillsMutation()

  const { appointmentID, key } = useReduxSelector((s) => s.appointments.appointments.current_appointment)
  const { data: medicationData } = useGetMedicationsQuery({});
  const { currentIb } = useFetchAndTransformData();

  const { refetch: refetchActions } = useActionsDataQuery({
    illness_history: currentIb?.id!,
    appointment_id: appointmentID!,
    type: key!
  }, { skip: !currentIb?.id && (typeof appointmentID !== 'number') && !!key })

  useEffect(() => {
    if (key && typeof appointmentID === 'number') {
      refetchActions().then(res => {
        const foundMedications = medicationData?.filter((pill) => res.data?.pills.some((resPill) => pill.id === resPill.id))

        const convertToPill = foundMedications?.map(medicineToPill)

        if (convertToPill) {
          setMedications(convertToPill)
          dispatch(setSelectedMedication(convertToPill));
        }
      })
    }

  }, [key, appointmentID])

  const handleClickedRowItem = (event: RowSelectedEvent<Medication, any>) => {
    const medicine = event.data
    if (medicine) {
      const newPill: Pill = medicineToPill(medicine)

      const isSelected = medications.some(
        (selectedItem) => selectedItem.id === newPill.id,
      );

      const updatedSelectedItems = isSelected
        ? medications.filter(
          (selectedItem) => selectedItem.id !== newPill.id,
        )
        : [...medications, newPill];

      setMedications(updatedSelectedItems)
    }
  }

  const handleClickOk = () => {
    const pills: PillActions = medications.map(pill => ({
      ...pill,
      model_type: key!,
      illness_history: currentIb?.id,
      model_ref_id: appointmentID!,
    }))

    if (pills.length) {
      createPill(pills).then(() => {
        dispatch(setSelectedMedication(medications));
        toggleMedicalsModal()
      })
    }
  }
  return {
    handleClickOk,
    handleClickedRowItem,
    medications,
    toggleMedicalsModal,
    isOpen,
  };
};
