import { Grid } from '@mui/material';
import { Table } from 'components/CollapseTable/CollapseTable';
import { proceduresColDef } from './columns';
import { useProceduresSelector } from 'features/Prodcedures/model/selectors/useProceduresSelector';

export const ProceduresTable = () => {
  const { selectedProcedureItems } = useProceduresSelector();

  const groupedData: Record<keyof typeof titles, any[]> = {
    procedures: selectedProcedureItems,
  };

  const titles = {
    procedures: 'Процедуры',
  };

  const columnDef = {
    procedures: proceduresColDef,
  };

  return (
    <Grid item xs={12} md={12} className="bg-[#F5F5F5]">
      {Object.entries(groupedData).map(([cito, data], index) => {
        if (!data.length) {
          return;
        }
        return (
          <Table
            key={index}
            isCollapsible
            data={data}
            // @ts-expect-error
            columns={columnDef[cito]}
            cito={cito}
            // handleRowClicked={handleClickedRowTable}
            // @ts-expect-error
            collapseTitle={`${titles[cito]}`}
          />
        );
      })}
    </Grid>
  );
};
