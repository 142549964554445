import { LabResearchActions, MedicalServiceActions } from 'components/AppointmentActionSection/types';
import {
  setSelectedConsultingItems,
  setSelectedResearchItems,
} from 'features/ConsultingAndResearch/model/slice/consultingAndResearchSlice';
import {
  LabResearchesTypes,
  MedServiceCategoryTypes,
} from 'features/ConsultingAndResearch/model/types';
import { useReduxDispatch, useReduxSelector } from 'hooks/useReduxHook';
import { useCallback } from 'react';
import { MedServiceSpecialties } from 'types/appointmentTypes';

export const useConsultingResearchSelector = () => {
  const dispatch = useReduxDispatch();

  const { selectedConsultingItems, selectedResearchItems } = useReduxSelector(
    (state) => state.consultingAndResearch,
  );

  // console.log({ selectedConsultingItems, selectedResearchItems });

  const dispatchConsoltingItems = (items: MedServiceSpecialties) => {
    dispatch(setSelectedConsultingItems(items));
  }

  const dispatchResearchItems = (items: LabResearchesTypes) => {
    dispatch(setSelectedResearchItems(items));
  }

  return {
    selectedConsultingItems,
    selectedResearchItems,
    dispatchConsoltingItems,
    dispatchResearchItems,
  };
};
