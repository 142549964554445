import { useGetMedServiceGroupQuery } from 'features/ConsultingAndResearch/service/consultingAndReseachService';
import { useProceduresSelector } from 'features/Prodcedures/model/selectors/useProceduresSelector';
import {
  ProcedureType,
  ProcedureTypes,
} from 'features/Prodcedures/model/types';
import { useCallback, useState } from 'react';

export const useProceduresModalHook = () => {
  const { data: proceduresData } = useGetMedServiceGroupQuery({}); // getProceduresQuery is renamed to getMedServiceGroupQuery

  return {
    proceduresData,
  };
};
