import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LabResearchActions, MedicalServiceActions } from 'components/AppointmentActionSection/types';
import {
  LabResearchesTypes,
  MedServiceCategoryTypes,
} from 'features/ConsultingAndResearch/model/types';
import { MedServiceSpecialties } from 'types/appointmentTypes';

interface ConsultingAndResearchState {
  selectedResearchItems: LabResearchesTypes;
  selectedConsultingItems: MedServiceSpecialties;
}

const initialState: ConsultingAndResearchState = {
  selectedResearchItems: [],
  selectedConsultingItems: [],
};

const consultingAndResearchSlice = createSlice({
  name: 'consultingAndResearch',
  initialState,
  reducers: {
    setSelectedResearchItems: (
      state,
      action: PayloadAction<LabResearchesTypes>,
    ) => {
      state.selectedResearchItems = action.payload;
    },
    setSelectedConsultingItems: (
      state,
      action: PayloadAction<MedServiceSpecialties>,
    ) => {
      state.selectedConsultingItems = action.payload;
    },
  },
});

export const { setSelectedResearchItems, setSelectedConsultingItems } =
  consultingAndResearchSlice.actions;

export default consultingAndResearchSlice.reducer;
