import { Grid } from '@mui/material';
import { Table } from 'components/CollapseTable/CollapseTable';
import { GetDocPatientsColDef } from 'containers/Doctors/PatientDoctors/Tabs/MyPatientsDoc/_components/column';
import { useConsultingResearchSelector } from 'features/ConsultingAndResearch/model/selectors/consultingReserchSelector';
import { GetMyPatients } from 'features/DoctorsRoleService/types';
import { consultingItemsColDef, labResearchesColDef } from './columns';
import { LabResearchesTypes } from 'features/ConsultingAndResearch/model/types';
import { useEffect } from 'react';
import { useReduxDispatch } from 'hooks/useReduxHook';
import { setSelectedResearchItems } from 'features/ConsultingAndResearch/model/slice/consultingAndResearchSlice';

interface Props {
  labResearchList?: LabResearchesTypes;
}

export const ConsultingTable = ({
  // rowData,
  // numberOfPatient,
  // handleClickedRowTable,
  labResearchList,
}: Props) => {
  const { dispatchResearchItems, selectedResearchItems } =
    useConsultingResearchSelector();

  // useEffect(() => {
  //   if (labResearchList && labResearchList.length > 0) {
  //
  //   }
  // }, [labResearchList]);

  const groupedData: Record<keyof typeof titles, any[]> = {
    research: selectedResearchItems,
  };

  const titles = {
    research: 'Лабораторные исследования',
  };

  const columnDef = {
    research: labResearchesColDef,
  };

  return (
    <Grid item xs={12} md={12} className="bg-[#F5F5F5]">
      {Object.entries(groupedData).map(([cito, data], index) => {
        if (!data.length) {
          return;
        }
        return (
          <Table
            key={index}
            isCollapsible
            data={data}
            // @ts-expect-error
            columns={columnDef[cito]}
            cito={cito}
            // handleRowClicked={handleClickedRowTable}
            // @ts-expect-error
            collapseTitle={`${titles[cito]}`}
          />
        );
      })}
    </Grid>
  );
};
