import { IColumnType } from 'components/CollapseTable/CollapseTable';
import dayjs from 'dayjs';
import { ProcedureType } from 'features/Prodcedures/model/types';

export const proceduresColDef: IColumnType<ProcedureType>[] = [
  {
    key: '',
    title: '',
    width: 30,
  },
  {
    key: '',
    title: '',
    width: 50,
    render: (column, item) => {
      return <div className=" flex items-center justify-center"></div>;
    },
  },
  {
    key: 'name',
    title: 'Назначение',
  },
  {
    key: '',
    title: 'О',
    width: 50,
  },
  {
    key: '',
    title: 'Д',
    width: 50,
  },
  {
    key: '',
    title: 'Н',
    width: 50,
  },
  {
    key: 'created_at',
    title: 'Дата',
    width: 100,
    render: (column, item) => dayjs(item.created_at).format('DD-MM-YYYY'),
  },
  {
    key: '',
    title: 'Способ оплаты',
    width: 160,
  },
  {
    key: 'cost',
    title: 'Цена',
    width: 120,
  },
  {
    key: '',
    title: ``,
    width: 120,
  },
];
