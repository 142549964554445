import { useCallback, useEffect, useState } from 'react';
import { useProceduresSelector } from '../model/selectors/useProceduresSelector';
import { ProcedureType, ProcedureTypes } from '../model/types';
import { useActionsDataQuery, useCreateProceduresMutation } from 'components/AppointmentActionSection/service';
import { ProcedureActions } from 'components/AppointmentActionSection/types';
import dayjs from 'dayjs';
import { useReduxSelector } from 'hooks/useReduxHook';
import { useGetMedServiceGroupQuery } from 'features/patient/service';
import { AppointmentMedServiceTypes, MedServiceSpecialty } from 'types/appointmentTypes';
import { useFetchAndTransformData } from 'features/Appointments/slice/useAppoitnmentsSelectors';

export const useProceduresViewHook = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMedicalsModal = useCallback(() => {
    setIsOpen((oldState) => !oldState);
  }, []);

  const [selectedItems, setSelectedItems] = useState<ProcedureTypes>([]);

  const { appointmentID, key } = useReduxSelector((s) => s.appointments.appointments.current_appointment)

  const { dispatchProcedureItems } = useProceduresSelector();
  const [createProcedures] = useCreateProceduresMutation()

  const { data: medServices } = useGetMedServiceGroupQuery({});
  const { currentIb } = useFetchAndTransformData();

  const { refetch: refetchActions } = useActionsDataQuery({
    illness_history: currentIb?.id!,
    appointment_id: appointmentID!,
    type: key!
  }, { skip: !currentIb?.id && (typeof appointmentID !== 'number') && !!key })

  useEffect(() => {
    if (key && typeof appointmentID === 'number') {
      refetchActions().then(res => {
        const foundServices = medServices?.flatMap(service => service.med_service_specialty).filter((service) => {
          console.log({ service, procedures: res.data?.procedures, found: res.data?.procedures.find(procedure => service.id === procedure.id) })
          return !!res.data?.procedures.some(procedure => service.id === procedure.medical_service)
        })
        console.log({ res, foundServices, medServices })
        if (foundServices) {
          setSelectedItems(foundServices as ProcedureTypes)
          dispatchProcedureItems(foundServices as ProcedureTypes)
        }
      })
    }

  }, [key, appointmentID])

  const handleProceduresSelectItems = (item: ProcedureType) => {
    const isSelected = selectedItems.some(
      (selectedItem) => selectedItem.id === item.id,
    );

    const updatedSelectedItems = isSelected
      ? selectedItems.filter(
        (selectedItem) => selectedItem.id !== item.id,
      )
      : [...selectedItems, item];

    setSelectedItems(() => updatedSelectedItems);
  }

  const handleClickOk = () => {
    const procedures: ProcedureActions = selectedItems.map((procedure) => ({
      id: procedure.id,
      medical_service: procedure.id,
      illness_history: currentIb?.id,
      quantity: 1,
      frequency: 'каждый день',
      comments: '',
      start_date: dayjs(new Date).format('DD-MM-YYYY'),
      created_by: procedure.created_by,
      model_ref_id: appointmentID!,
      model_type: key!,
      modified_by: procedure.modified_by
    }))

    createProcedures(procedures).then(() => {
      dispatchProcedureItems(selectedItems);
      setIsOpen(false)
    })
  }

  return {
    toggleMedicalsModal,
    isOpen,
    handleProceduresSelectItems,
    selectedItems,
    handleClickOk
  };
};
