import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Pill } from 'types/patientTypes';

interface MedicationState {
  medications: Pill[];
  selectedMedications: Pill[];
}

const initialState: MedicationState = {
  medications: [],
  selectedMedications: [] as Pill[],
};

export const medicationSlice = createSlice({
  name: 'medication',
  initialState,
  reducers: {
    setMedications: (state, action) => {
      state.medications = action.payload;
    },
    setSelectedMedication: (state, action: PayloadAction<Pill[]>) => {
      state.selectedMedications = action.payload;
    },
  },
});

export const { setMedications, setSelectedMedication } =
  medicationSlice.actions;
