import { Box } from '@mui/material';
import { MedicationColumnDefs } from './colDefsForMedicationTable';
import { useMedicationTableHook } from './hook';
import { ReuseableAgGridTable } from 'components/ReuseableAgGridTable';
import { RowSelectedEvent } from 'ag-grid-community';
import { Medication } from 'features/Medicals/model/types/medicationTypes';
import { Pill } from 'types/patientTypes';

type Props = {
  handleClickedRowItem: (event: RowSelectedEvent<Medication, any>) => void;
  selectedPills: Pill[];
};

export const MedicationTableView = (props: Props) => {
  const { handleClickedRowItem, selectedPills } = props;
  const { getMedicationData } = useMedicationTableHook();

  return (
    <Box className="bg-red-500">
      <ReuseableAgGridTable
        selectedItems={selectedPills}
        columnDefs={MedicationColumnDefs}
        rowData={getMedicationData}
        handleClicedRow={handleClickedRowItem}
      />
    </Box>
  );
};
