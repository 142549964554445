export const CheckBoxMockData = [
  {
    label: 'N,',
    value: 'n',
  },
  {
    label: 'горизонтальная,',
    value: 'horizontal',
  },
  {
    label: 'вертикальная,',
    value: 'vertical',
  },
  {
    label: 'отклонена влево,',
    value: 'left',
  },
  {
    label: 'отклонена вправо',
    value: 'right',
  },
];
