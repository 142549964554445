export const COLORS = {
    transparent: 'transparent',
    current: 'currentColor',
    white: '#ffffff',
    purple: '#3f3cbb',
    midnight: '#121063',
    metal: '#565584',
    tahiti: '#3ab7bf',
    silver: '#ecebff',
    'bubble-gum': '#ff77e9',
    bermuda: '#78dcca',
    'primary-text': '#303A42',
    'light-blue': '#EFF8FF',
    'icon-color': '#AED1ED',
    'gray-color': '#C5C5C5',
    blue: '#0C4B7E',
    'dark-blue': '#002F55',
    'brown-color': '#B58D50',
    highlight: '#FFFBD8',
    'blue-text': '#3B7EB4',
    'gray-text': '#9A9A9A',
    'dark-gray-text': '#6D7378',
    'darker-gray-text': '#545C62',
    'pagination-highlight': '#F5F5FF',
    'light-gray': '#E6E6E6',
    'lighter-gray': '#D9D9D9',
    'light-brown': '#C1995C',
    'light-red': '#FFF1F3',
    'green-highlight-bg': '#D9FFED',
    'green-highlight': '#1FEA89',
    'lighter-blue': '#DBEEFE',
    'darkest-gray': '#303A42',
    black: '#0E1012',
    'dark-gray': '#17232D',
    'red-color': '#EF495D',
    'lighter-brown': '#FFFAF1',
    'light-yellow': '#EDD0A5',
    'light-pink': '#FF7485',
    'darker-blue': '#76ABD6',
    error: '#FF99A5',
    'gray-border-color': '#E2E3E4',
    'brown-bg': '#F5E6D0',
};

export const AddAlpha = (hex: string, alpha: number) =>
    `${hex}${Math.floor(alpha * 255)
        .toString(16)
        .padStart(2, '0')}`;
