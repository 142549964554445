import { LabResearchAction, LabResearchActions, MedicalServiceAction, MedicalServiceActions } from 'components/AppointmentActionSection/types';
import { useCallback, useEffect, useState } from 'react';
import { useConsultingResearchSelector } from '../model/selectors/consultingReserchSelector';
import { useActionsDataQuery, useCreateLabResearchMutation, useCreateMedicalServicesMutation } from 'components/AppointmentActionSection/service';
import { MedServiceSpecialties, MedServiceSpecialty } from 'types/appointmentTypes';
import { LabResearchesType, LabResearchesTypes } from '../model/types';
import { useReduxSelector } from 'hooks/useReduxHook';
import { useFetchAndTransformData } from 'features/Appointments/slice/useAppoitnmentsSelectors';
import { useGetLabsGroupByCategoryQuery, useGetMedServiceGroupQuery } from '../service/consultingAndReseachService';
import { setSelectedResearchItems } from '../model/slice/consultingAndResearchSlice';

export const useConsultingAndResearchHook = () => {
  const [studiesModalOpen, setStudiesModalOpen] = useState(false);
  const [consultationModalOpen, setConsultationModalOpen] = useState(false);

  const { dispatchResearchItems, dispatchConsoltingItems, selectedResearchItems, selectedConsultingItems } = useConsultingResearchSelector();
  const [selectedItems, setSelectedItems] = useState<MedServiceSpecialties>(selectedConsultingItems);
  const [selectedLabResearch, setSelectedLabResearch] = useState<LabResearchesTypes>(selectedResearchItems);
  console.log({ selectedItems, selectedLabResearch });

  const { appointmentID, key } = useReduxSelector((s) => s.appointments.appointments.current_appointment)
  const { currentIb } = useFetchAndTransformData();

  const { data: medServices } = useGetMedServiceGroupQuery({});
  const { data: labResearches } = useGetLabsGroupByCategoryQuery({});
  const { refetch: refetchActions } = useActionsDataQuery({
    illness_history: currentIb?.id!,
    appointment_id: appointmentID!,
    type: key!
  }, { skip: !currentIb?.id && (typeof appointmentID !== 'number') && !!key })

  useEffect(() => {
    if (key && typeof appointmentID === 'number') {
      refetchActions().then(res => {
        const foundServices = medServices?.flatMap(service => service.med_service_specialty).filter((service) => {
          return res.data?.medical_services.find(medService => service.id === medService.id)
        })

        if (foundServices) {
          setSelectedItems(() => foundServices)
          dispatchConsoltingItems(foundServices)
        }

        const foundResearches = labResearches?.flatMap(research => research.lab_research).filter((research) => {
          return res.data?.lab_research.find(researchItem => research.id === researchItem.lab)
        })

        console.log({ foundResearches, foundServices });

        if (foundResearches) {
          setSelectedLabResearch(() => foundResearches)
          dispatchResearchItems(foundResearches)
        }
      })
    }

  }, [key, appointmentID])
  const [createLab] = useCreateLabResearchMutation()
  const [createMedicalService] = useCreateMedicalServicesMutation()

  const handleClickPopupMenu = useCallback((item: any) => {
    if (item.title === 'Исследование') {
      setStudiesModalOpen((prev) => !prev);
    } else if (item.title === 'Консультацию') {
      setConsultationModalOpen((prev) => !prev);
    }
  }, []);

  const handleCloseModal = (modalType: string) => {
    if (modalType === 'consultation') {
      setConsultationModalOpen(false);
    } else if (modalType === 'studies') {
      setStudiesModalOpen(false);
    }
  };

  const handleMedServiceCheckboxChange = (item: MedServiceSpecialty) => {
    const isSelected = selectedItems.some(
      (selectedItem) => selectedItem.id === item.id,
    );

    const updatedSelectedItems = isSelected
      ? selectedItems.filter(
        (selectedItem) => selectedItem.id !== item.id,
      )
      : [...selectedItems, item];
    console.log({ item, updatedSelectedItems }, 'MedicalServiceAction');

    setSelectedItems(() => updatedSelectedItems);
  }

  const handleLabResearchCheckboxChange = (item: LabResearchesType) => {
    const updatedSelectedLabResearch = !!selectedLabResearch.find((i) => i.id === item.id)
      ? selectedLabResearch.filter(
        (selectedLabResearchItem) => selectedLabResearchItem.id !== item.id,
      )
      : [...selectedLabResearch, item];

    setSelectedLabResearch(updatedSelectedLabResearch);
  }

  const handleOkBtn = () => {
    if (studiesModalOpen) {
      const labResearches: LabResearchActions = selectedLabResearch.map((res) => ({
        comments: '',
        model_ref_id: appointmentID!,
        model_type: key!,
        price: res.price,
        illness_history: currentIb?.id,
        lab: res.id,
        // lab_doctor: 2
      }))

      createLab(labResearches).then((res) => {
        dispatchResearchItems(selectedLabResearch);
      }).finally(() => {
        setStudiesModalOpen(false)
      })
    }
    if (consultationModalOpen) {
      const medicalServices: MedicalServiceActions = selectedItems.map((service) => ({
        consulted_doctor: service.doctor[0],
        created_by: service.created_by,
        medical_service: service.id,
        model_ref_id: appointmentID!,
        model_type: key!,
        illness_history: currentIb?.id,
        modified_by: service.modified_by
      }))

      createMedicalService(medicalServices).then((res) => {
        dispatchConsoltingItems(selectedItems);
      }).finally(() => {
        setConsultationModalOpen(false);
      })
    }
  }


  return {
    handleClickPopupMenu,
    studiesModalOpen,
    consultationModalOpen,
    handleCloseModal,
    selectedItems,
    handleMedServiceCheckboxChange,
    selectedLabResearch,
    handleLabResearchCheckboxChange,
    handleOkBtn
  };
}
