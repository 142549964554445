import { setProcedures } from 'features/Prodcedures/model/slice/proceduresSlice';
import { ProcedureTypes } from 'features/Prodcedures/model/types';
import { useReduxDispatch, useReduxSelector } from 'hooks/useReduxHook';

export const useProceduresSelector = () => {
  const dispatch = useReduxDispatch();
  const { procedures: selectedProcedureItems } = useReduxSelector(
    (state) => state.procedures,
  );

  const dispatchProcedureItems = (items: ProcedureTypes) => {
    dispatch(setProcedures(items));
  }

  return {
    selectedProcedureItems,
    dispatchProcedureItems,
  };
};
