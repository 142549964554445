import { LabResearchList } from 'features/ConsultingAndResearch/model/types';
import { ApiSlice } from 'features/api/apiSlice';
import { AppointmentActions, LabResearchAction, LabResearchActions, LabResearchActionsList, MedicalServiceAction, MedicalServiceActions, MedicalServiceActionsList, PillAction, PillActions, PillActionsList, ProcedureActions, ProcedureActionsList } from './types';
import { ProcedureTypes } from 'features/Prodcedures/model/types';

export const consultingAndResearchService = ApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        actionsData: builder.query<AppointmentActions, { illness_history: number, appointment_id: number, type: string }>({
            query: ({ appointment_id, illness_history, type }) => ({
                url: `/sanatorium/appointments/actions/get_by_type?illness_history=${illness_history}&appointment=${appointment_id}&model_type=${type}`,
                method: 'GET',
            }),
        }),
        createProcedures: builder.mutation<
            ProcedureActionsList,
            ProcedureActions
        >({
            query: (data) => ({
                url: `/sanatorium/appointments/actions/procedures/create`,
                method: 'POST',
                body: data,
            }),
        }),
        createPills: builder.mutation<
            PillActionsList,
            PillActions
        >({
            query: (data) => ({
                url: `/sanatorium/appointments/actions/pills/create`,
                method: 'POST',
                body: data,
            }),
        }),
        createMedicalServices: builder.mutation<
            MedicalServiceActionsList,
            MedicalServiceActions
        >({
            query: (data) => ({
                url: `/sanatorium/appointments/actions/medical_services/create`,
                method: 'POST',
                body: data,
            }),
        }),
        createLabResearch: builder.mutation<
            LabResearchActionsList,
            LabResearchActions
        >({
            query: (data) => ({
                url: `/sanatorium/appointments/actions/lab_research/create`,
                method: 'POST',
                body: data,
            }),
        }),
    }),
});

export const {
    useActionsDataQuery,
    useCreateLabResearchMutation,
    useCreateMedicalServicesMutation,
    useCreatePillsMutation,
    useCreateProceduresMutation
} = consultingAndResearchService;
